import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { useTrail, useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

export const fragment = graphql`
  fragment HomeArcticlesFragment on WordPress_Page_Homefields {
    articles {
      title
      smallTitle
      list {
        ... on WordPress_Post {
          id
          title
          slug
          blogFilters {
            edges {
              node {
                name
              }
            }
          }
          blogFields {
            featuredInfos {
              title
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const HomeArticles = ({ articles }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [animatedButton, setAnimatedButton] = useState(false)
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [isDesktop, setIsDesktop] = useState()
  const titleRef = useRef()
  const buttonRef = useRef()
  const ref = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const blogsAnimation = useTrail(articles.list.length, {
    opacity: animatedContent ? 1 : 0,
    x: animatedContent ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 8,
      tension: 2500,
      friction: 400
    },
    delay: animatedContent ? 100 : 50
  })

  const buttonAnimation = useSpring({
    from: {
      opacity: 0,
      transform: `translate3d(0,20px,0)`
    },
    opacity: animatedButton ? 1 : 0,
    transform: animatedButton ? `translate3d(0px,0px,0px)` : `translate3d(0,20px,0)`,
    config: { mass: 5, tension: 300, friction: 50 }
  })

  return (
    <Outer>
      <RelatedBlock>
        <IntroRelated>
          <Observer element={titleRef.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
            <Title style={titleAnimation} ref={titleRef}>
              {articles.title}
            </Title>
          </Observer>
        </IntroRelated>
        <Observer element={ref.current} toggle={animatedContent} setToggle={setAnimatedContent}>
          <BlogFeatured ref={ref}>
            {blogsAnimation.map(({ x, ...rest }, index) => (
              <BlogItem
                key={index}
                to={`/blog/${articles.list[index].slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/blog/${articles.list[index].slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
                style={{
                  ...rest,
                  // eslint-disable-next-line no-shadow
                  transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                }}
              >
                <WrapItem>
                  <WrapBlogImage>
                    <Img
                      style={{ position: 'absolute' }}
                      fluid={
                        articles.list[index].blogFields.featuredInfos.image.imageFile
                          .childImageSharp.fluid
                      }
                      alt={articles.list[index].blogFields.featuredInfos.image.altText}
                    />
                  </WrapBlogImage>
                  <ItemDesc>
                    {articles.list[index].blogFilters.edges.map((cat, i) => (
                      <span key={i}>{cat.node.name} </span>
                    ))}
                    <h2> {articles.list[index].title} </h2>
                  </ItemDesc>
                </WrapItem>
              </BlogItem>
            ))}
          </BlogFeatured>
        </Observer>
        <Observer element={buttonRef.current} toggle={animatedButton} setToggle={setAnimatedButton}>
          <WrapButtonAll>
            <AllArticlesButton
              ref={buttonRef}
              style={buttonAnimation}
              to="/blog"
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/blog`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              View all blog posts
            </AllArticlesButton>
          </WrapButtonAll>
        </Observer>
      </RelatedBlock>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  margin: 0 auto;
`

const RelatedBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 12.5rem 0;

  @media (max-width: 1024px) {
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0 10rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 1rem 0 5rem 0;
  }
`

const IntroRelated = styled.div`
  display: block;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;

  @media (max-width: 1024px) {
    margin: 5rem auto;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    margin: 5rem auto;
  }
`

const Title = styled(animated.h2)`
  color: ${props => props.theme.colours.darkGrey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  letter-spacing: -0.075rem;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    font-size: 5.5rem;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  @media (max-width: 650px) {
    font-size: 7rem;
    margin-left: 3.5rem;
    margin-bottom: 5rem;
  }
`

const BlogFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 80%;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
  }
`

const WrapItem = styled.div`
  width: 100%;

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }
`

const WrapBlogImage = styled.div`
  position: relative;
`

const ItemDesc = styled.div`
  padding: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    letter-spacing: 0.15rem;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
    line-height: 1.75;

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }

    &:last-child() {
      margin-right: 0;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.5rem;
    line-height: 1.33;
    letter-spacing: -0.075rem;
    margin-top: 1.2rem;
    margin-bottom: 0;
    transition: color 0.25s ease;

    @media (max-width: 650px) {
      font-size: 3.15rem;
      margin-top: 2rem;
    }
  }
`

const BlogItem = styled(animated(Link))`
  width: 30%;
  margin-bottom: 6rem;
  border: 1px solid ${props => props.theme.colours.lightGrey};
  margin-right: 5%;
  transition: background-color 0.5s ease;

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${WrapBlogImage} {
    ${aspectRatio(344, 195)}
  }

  @media (max-width: 1024px) {
    width: 47.5%;
    margin-right: 2.5%;
    margin-bottom: 2.5rem;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 2.5%;
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-right: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  img {
    transition: transform 0.5s ease !important;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};

    & ${ItemDesc} span {
      color: ${props => props.theme.colours.darkGrey};
    }

    & ${ItemDesc} h2 {
      color: ${props => props.theme.colours.white};
    }

    img {
      transform: scale(1.1);
    }
  }
`

const WrapButtonAll = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
`

const AllArticlesButton = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 3.5rem;
  display: inline-block;
  min-width: 20rem;
  text-align: center;
  transition: background-color 0.25s ease, color 0.25s ease;
  backface-visibility: hidden;

  @media (max-width: 1024px) {
    margin-top: 3rem;
    font-size: 1.5rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  @media (max-width: 650px) {
    margin-top: 4.5rem;
    margin-left: 0;
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

export default HomeArticles
