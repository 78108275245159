import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HomeHero from '../components/home/hero'
import HomeIntroduction from '../components/home/introduction'
import HomeStatements from '../components/home/statements'
import HomeServices from '../components/home/services'
import HomeWork from '../components/home/work'
import HomeClient from '../components/home/client'
import HomeArticles from '../components/home/articles'
import SEO from '../components/home/seo'

const Home = ({ data, pageContext }) => (
  <Layout>
    <Helmet>
      <meta name="facebook-domain-verification" content="74xev5astxyr1cl7h1gchh6ohx7cgw" />
    </Helmet>
    <SEO pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
    <HomeHero
      hero={data.wordPress.pageBy.homeFields.hero}
      logos={data.wordPress.globalOptions.optionFields.footer.logoListGrey}
    />
    <HomeIntroduction introduction={data.wordPress.pageBy.homeFields.introduction} />
    <HomeStatements statements={data.wordPress.pageBy.homeFields.statements} />
    <HomeServices services={data.wordPress.pageBy.homeFields.services} />
    <HomeWork work={data.wordPress.pageBy.homeFields.work} />
    <HomeClient clients={data.wordPress.clients} intro={data.wordPress.pageBy.homeFields.client} />
    <HomeArticles articles={data.wordPress.pageBy.homeFields.articles} />
  </Layout>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...HomeSeoFragment
        homeFields {
          ...HomeHeroFragment
          ...HomeIntroductionFragment
          ...HomeStatementsFragment
          ...HomeServicesFragment
          ...HomeWorkFragment
          ...HomeClientIntroFragment
          ...HomeArcticlesFragment
        }
      }
      clients(where: { orderby: { field: MENU_ORDER, order: ASC } }, first: 50) {
        edges {
          node {
            clientFields {
              ...HomeClientFragment
            }
          }
        }
      }
      globalOptions {
        optionFields {
          footer {
            logoListGrey {
              image {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              link
            }
          }
        }
      }
      services(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  }
`
