import React, { useState, useEffect, useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import MaskedImage from '../masked-image'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

export const fragment = graphql`
  fragment HomeServicesFragment on WordPress_Page_Homefields {
    services {
      smallTitle
      buttonText
      buttonLink
      background {
        sourceUrl
        mediaItemId
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      serviceList {
        text
        title
        image {
          sourceUrl
          mediaItemId
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const HomeServices = ({ services }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedContent, setAnimatedContent] = useState(true)
  const [titleClicked, setTitleClicked] = useState(0)
  const [isDesktop, setIsDesktop] = useState(null)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const contentAnimation = useSpring({
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  const buttonAnimation = useSpring({
    from: { opacity: 0, transform: `translate(0px,50px)` },
    to: { opacity: 1, transform: `translate(0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <ServicesBlock>
        <ServicesList>
          <Left>
            <SmallTitle>{services.smallTitle}</SmallTitle>
            {services.serviceList.map((item, index) => (
              <ServiceTitle
                onClick={() => {
                  setAnimatedContent(false)
                  setTimeout(() => {
                    setAnimatedContent(true)
                    setTitleClicked(index)
                  }, 200)
                }}
                className={` ${index === titleClicked ? 'active' : ''}`}
              >
                {item.title}
              </ServiceTitle>
            ))}
            <ServicesButton
              style={buttonAnimation}
              to={services.buttonLink}
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(services.buttonLink)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              {services.buttonText}
            </ServicesButton>
          </Left>
          <Right>
            {services.serviceList.map((item, index) => (
              <ServiceItem
                className={` ${index === titleClicked ? 'active' : ''}`}
                key={item.title}
                // style={isDesktop ? { lineHeight: 1 } : contentAnimation}
              >
                <WrapImage>
                  <Img fluid={item.image.imageFile.childImageSharp.fluid} />
                </WrapImage>
                <ServiceText>{item.text}</ServiceText>
              </ServiceItem>
            ))}
          </Right>
          <ServicesButtonMobile
            style={buttonAnimation}
            to={services.buttonLink}
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(services.buttonLink)
                setTransitionActive(false)
              }, 500)
            }}
          >
            {services.buttonText}
          </ServicesButtonMobile>
        </ServicesList>
      </ServicesBlock>
      <WrapImageBg>
        <MaskedImage fluid={services.background.imageFile.childImageSharp.fluid} />
      </WrapImageBg>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  margin: 0 auto;
`

const ServicesBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-bottom: 10rem;
  }
`

const SmallTitle = styled.h4`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    margin-top: 0;
    font-size: 1.7rem;
  }
`

const Left = styled.div`
  width: 40%;

  @media (max-width: 769px) {
    width: 100%;
  }
`

const Right = styled.div`
  position: relative;
  width: 55%;

  @media (max-width: 769px) {
    width: 100%;
    height: auto;
  }
`

const ServicesList = styled(animated.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 80%;
  height: 100%;

  @media (max-width: 769px) {
    justify-content: center;
    display: block;
  }
`

const ServiceTitle = styled(animated.h3)`
  position: relative;
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  font-weight: 700;
  letter-spacing: -0.2rem;
  margin-bottom: 3rem;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s ease, color 0.3s ease;

  &.active {
    color: ${props => props.theme.colours.white};
    opacity: 1;
  }
`

const ServiceItem = styled(animated.div)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, color 0.3s ease;

  &.active {
    color: ${props => props.theme.colours.white};
    opacity: 1;

    @media (max-width: 769px) {
      display: block;
    }
  }

  @media (max-width: 769px) {
    position: relative;
    top: 0;
    transform: translateY(0);
    transition: none;
    display: none;
  }
`

const WrapImage = styled.div`
  position: relative;
  width: 100%;
  ${aspectRatio(572, 371)}

  &:before {
    padding-top: 0 !important;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 769px) {
    margin-top: 5rem;
    width: 100%;

    &:before {
      padding-top: 0 !important;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  > * {
    position: absolute;
  }
`

const ServiceText = styled.h5`
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.75;
  margin-top: 5rem;

  @media (max-width: 769px) {
    margin-top: 2.5rem;
    font-size: 2.2rem;
  }
`

const WrapImageBg = styled.div`
  width: 100%;
  height: 100%;
  ${aspectRatio(572, 360)}
  z-index: -1;

  @media (max-width: 769px) {
    ${aspectRatio(428, 950)}
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
  }
`

const ServicesButton = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.white};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  min-width: 20rem;
  text-align: center;
  display: inline-block;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 769px) {
    display: none;
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
  }
`

const ServicesButtonMobile = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.white};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  min-width: 20rem;
  text-align: center;
  display: none;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 769px) {
    display: inline-block;
    font-size: 1.7rem;
    border-radius: 5rem;
    margin-top: 2.5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.pink};
  }
`

export default HomeServices
