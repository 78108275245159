import React, { useContext, useState, useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import { ButtonContext } from '../context/button-context'
import InView from '../in-view'

import HeroFlowmap from '../hero-flowmap'

export const fragmentQl = graphql`
  fragment HomeHeroFragment on WordPress_Page_Homefields {
    hero {
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
      imageMobile {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
      subtitle
      buttonText
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
    }
  }
`

const HomeHero = ({ hero, logos }) => {
  // eslint-disable-next-line no-unused-vars
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const subTitleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      {!isDesktop && (
        <InView element={triggerRef.current} setToggle={setButtonTriggers} location="hero">
          <TriggerButton ref={triggerRef} />
        </InView>
      )}
      <WrapHero>
        <HeroFlowmap
          mobile={hero.imageMobile.imageFile.childImageSharp.fluid}
          desktop={hero.image.imageFile.publicURL}
        />
        <WrapText>
          <animated.h1 style={titleAnimation}>{hero.title}</animated.h1>
          <animated.h2
            style={subTitleAnimation}
            dangerouslySetInnerHTML={{ __html: hero.subtitle }}
          ></animated.h2>
        </WrapText>
      </WrapHero>

      <WrapImageList>
        {logos.map((image, j) => (
          <ImageListWrap key={j}>
            <ImageListItem fluid={image.image.imageFile.childImageSharp.fluid} />
          </ImageListWrap>
        ))}
      </WrapImageList>
    </Outer>
  )
}

export default HomeHero

const Outer = styled.section`
  position: relative;
  min-height: 100vh;
  height: auto;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const WrapHero = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  position: absolute;
  width: calc(50% - 12rem);
  height: auto;
  top: 50%;
  right: 12rem;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    position: relative;
    top: unset;
    left: 0;
    width: 80%;
    margin: 5rem auto;
    transform: translateY(0);
    text-align: center;
    max-width: 450px;
  }

  @media (max-width: 650px) {
    margin: 5rem auto;
    width: 90%;
  }

  h1 {
    color: ${props => props.theme.colours.darkGrey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 8rem;
    line-height: 0.95;
    letter-spacing: -0.075rem;
    max-width: 14ch;
    margin-bottom: 25px;

    @media (max-width: 650px) {
      max-width: 20ch;
      font-size: 7.15rem;
      line-height: 1.15;
    }
  }

  h2 {
    p {
      color: ${props => props.theme.colours.darkGrey};
      font-family: ${props => props.theme.fonts.circular};
      font-weight: 300;
      font-size: 2.2rem;
      line-height: 1.5;
      max-width: 35ch;

      @media (max-width: 650px) {
        font-size: 2.5rem;
      }

      strong {
        color: ${props => props.theme.colours.pink};
        font-weight: 300;
      }
    }
  }
`

const WrapImageList = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  width: 575px;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 650px) {
    justify-content: space-between;
  }
`

const ImageListWrap = styled.div`
  width: 150px;
  height: 40px;
  margin-right: 2.5rem;

  @media (max-width: 650px) {
    width: 70px;
  }

  &:nth-child(2) {
    height: 45px !important;
  }

  &:nth-child(3) {
    height: 19px !important;
  }

  &:last-child {
    margin-right: 0;
  }
`

const ImageListItem = styled(Img)`
  width: 100%;
  height: 100%;

  & img {
    object-fit: contain !important;
  }
`
