import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
// import { useInView } from 'react-intersection-observer'
import { InView } from 'react-intersection-observer'
import MaskedImage from '../masked-image'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'
import { ScrollbarContext } from '../context/scrollbar-context'
import Observer from '../observer'

export const fragment = graphql`
  fragment HomeStatementsFragment on WordPress_Page_Homefields {
    statements {
      statement
      statementList {
        text
        title
        image {
          sourceUrl
          mediaItemId
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1100, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const HomeStatements = ({ statements }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [statementIn, setStatementIn] = useState(0)
  const [imgFade, setImgFade] = useState(false)
  const [statementImgToggle, setStatementImgToggle] = useState(false)
  const [scrollbar] = useContext(ScrollbarContext)
  const [isDesktop, setIsDesktop] = useState(null)
  const contentRef = useRef()
  const statementImgRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (statementImgRef.current) {
        statementImgRef.current.style.transform = `translateY(${offset.y}px)`
      }
    }

    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar])

  const contentAnimation = useSpring({
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const imgAnimation = useSpring({
    opacity: statementImgToggle ? 1 : 0,
    from: { opacity: 0 },
    config: {
      mass: 3,
      tension: 500,
      friction: 50
    }
  })

  const fadeImg = useSpring({
    opacity: imgFade ? 1 : 0,
    from: { opacity: 0 },
    config: {
      mass: 3,
      tension: 500,
      friction: 50
    }
  })

  return (
    <Outer>
      <StatementsBlock>
        <Observer
          element={contentRef.current}
          toggle={animatedContent}
          setToggle={setAnimatedContent}
        >
          <Statement
            style={contentAnimation}
            ref={contentRef}
            dangerouslySetInnerHTML={{ __html: statements.statement }}
          />
        </Observer>
        <StatementList>
          <InView
            as="div"
            threshold={0.35}
            onChange={(inView, entry) => {
              if (inView) {
                setStatementImgToggle(true)
              } else {
                setStatementImgToggle(false)
              }
            }}
          >
            <Left>
              {statements.statementList.map((item, index) => (
                <WrapText>
                  <InView
                    as="div"
                    threshold={1}
                    onChange={(inView, entry) => {
                      if (inView) {
                        setImgFade(false)
                        setTimeout(() => {
                          setStatementIn(index)
                          setImgFade(true)
                        }, 150)
                      }
                    }}
                  >
                    <StatementTitle>
                      <StatementNumber>{`0${index + 1}`}</StatementNumber>
                      {item.title}
                    </StatementTitle>
                    <StatementText>{item.text}</StatementText>
                    <WrapImageMobile>
                      <MaskedImage fluid={item.image.imageFile.childImageSharp.fluid} />
                    </WrapImageMobile>
                  </InView>
                </WrapText>
              ))}
            </Left>
          </InView>
        </StatementList>
      </StatementsBlock>
      {isDesktop && (
        <Right style={imgAnimation} ref={statementImgRef}>
          <WrapImage>
            {statements.statementList.map((item, index) => (
              <ImgWrap className={statementIn === index ? 'active' : ''}>
                <MaskedImage fluid={item.image.imageFile.childImageSharp.fluid} />
              </ImgWrap>
            ))}
          </WrapImage>
        </Right>
      )}
    </Outer>
  )
}

const Outer = styled.section``

const StatementsBlock = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 0 auto 15rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 767px) {
    width: calc(100% - 6rem);
    max-width: calc(100% - 50px);
    margin: 0 25px 10rem 25px;
  }
`

const Statement = styled(animated.div)`
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 16rem;

  @media (max-width: 1024px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 650px) {
    margin: 0;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5.5rem;
    line-height: 1.1;
    color: #373737;
    max-width: 28ch;

    @media (max-width: 1024px) {
      font-size: 3.85rem;
      line-height: 1.25;
    }

    @media (max-width: 650px) {
      font-size: 5rem;
    }

    strong {
      color: ${props => props.theme.colours.pink};
      font-weight: 300;
    }
  }
`

const StatementList = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const StatementNumber = styled.span`
  position: absolute;
  top: 1.2rem;
  left: -3rem;
  font-family: ${props => props.theme.fonts.circular};
  letter-spacing: 0.2rem;
  color: ${props => props.theme.colours.pink};
  font-size: 1.4rem;

  @media (max-width: 650px) {
    left: -2.5rem;
  }
`

const StatementTitle = styled.h4`
  position: relative;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;

  @media (max-width: 650px) {
    margin-bottom: 2rem;
  }
`

const StatementText = styled.p`
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.darkGrey};
  font-size: 2.2rem;

  @media (max-width: 650px) {
    font-size: 2.58rem;
    margin-bottom: 2.5rem;
  }
`

const Left = styled.div`
  width: 35%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Right = styled(animated.div)`
  position: absolute;
  width: 45%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1366px;
  top: 0;
  left: 45%;
  z-index: -1;
  will-change: top;
  transform: translate(0%, 0%);
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
`

const WrapText = styled(animated.div)`
  position: relative;
  margin: 5rem 0;
  padding: 15rem 0;

  @media (max-width: 1024px) {
    margin: 10rem 0;
    padding: 0;

    div:first-of-type {
      display: flex;
      flex-direction: column;
    }

    p {
      max-width: 50ch;
    }
  }

  @media (max-width: 650px) {
    margin: 10rem 0;
    padding: 0;
  }
`

const WrapImage = styled(animated.div)`
  position: relative;
  width: 100%;
  ${aspectRatio(572, 358)}
  border-radius: 20px;
  box-shadow: 0 33px 35px rgba(0, 0, 0, 0.16);

  @media (max-width: 1024px) {
    ${aspectRatio(354, 222)}
    width: 90%;
    margin-bottom: 8rem;
  }

  @media (max-width: 769px) {
    ${aspectRatio(354, 222)}
    width: 100%;
    margin-bottom: 8rem;
  }

  &::after {
    content: '';
    display: block;
  }

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const ImgWrap = styled.div`
  opacity: 0;
  transition: opacity 0.35s ease-in-out;

  &.active {
    opacity: 1;
  }
`

const WrapImageMobile = styled.div`
  position: relative;
  width: 100%;
  ${aspectRatio(572, 358)}
  display: none;

  @media (max-width: 1024px) {
    ${aspectRatio(354, 222)}
    width: 100%;
    margin-bottom: 8rem;
    border-radius: 10px;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.15));
    display: block;
  }

  &::after {
    content: '';
    display: block;
  }

  > * {
    position: absolute;
    border-radius: 10px;
  }
`

export default HomeStatements
