import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { useTrail, useSpring, animated } from "react-spring"
import styled from "styled-components"
import Observer from "../observer"
import BackgroundColor from "../background-color-white"

export const fragment = graphql`
  fragment HomeClientFragment on WordPress_Client_Clientfields {
    featuredInfos {
      logo {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
    }
  }
  fragment HomeClientIntroFragment on WordPress_Page_Homefields {
    client {
      smallTitle
      title
    }
  }
`

const HomeClient = ({ clients, intro }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animationToggle, setAnimationToggle] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const titleRef = useRef()
  const contentRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const clientItemAnimation = useTrail(clients.edges.length, {
    opacity: animationToggle ? 1 : 0,
    from: { opacity: 0 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175,
    },
    delay: animationToggle ? 300 : 50,
  })

  return (
    <Outer>
      <ClientBlock>
        <IntroClient>
          <Observer
            element={titleRef.current}
            toggle={animatedTitle}
            setToggle={setAnimatedTitle}
          >
            <Title style={titleAnimation} ref={titleRef}>
              {intro.title}
            </Title>
          </Observer>
        </IntroClient>
        <Observer
          element={contentRef.current}
          toggle={animationToggle}
          setToggle={setAnimationToggle}
        >
          <ClientFeatured ref={contentRef}>
            {clientItemAnimation.map(({ ...rest }, index) => {
              const navItem = clients.edges[index]
              return (
                <ClientItem
                  style={{
                    ...rest,
                  }}
                  key={index}
                >
                  <img
                    src={
                      navItem.node.clientFields.featuredInfos.logo.imageFile
                        .publicURL
                    }
                    alt=""
                  />
                </ClientItem>
              )
            })}
          </ClientFeatured>
        </Observer>
      </ClientBlock>
      {/* <BackgroundColor topOffset={isDesktop ? -500 : -100} /> */}
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  margin: 0 auto;
`

const ClientBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 25rem 0 12.5rem 0;

  @media (max-width: 1024px) {
    width: 80%;
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 5rem 0;
  }
`

const IntroClient = styled.div`
  display: block;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;

  @media (max-width: 1024px) {
    margin: 5rem auto;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    margin: 5rem auto;
  }
`

const Title = styled(animated.h2)`
  color: var(--darkgrey);
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  letter-spacing: -0.075rem;
  margin-bottom: 8rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 5.5rem;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  @media (max-width: 650px) {
    font-size: 7rem;
    margin-left: 3.5rem;
    margin-bottom: 5rem;
    text-align: left;
  }
`

const ClientFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 125rem;
  margin: 0 auto;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }

  @media (max-width: 650px) {
    justify-content: space-around;
    width: calc(100% - 7rem);
    padding: 0;
  }
`

const ClientItem = styled(animated.div)`
  width: 14.5%;
  margin: 2rem 2rem 4rem 2rem;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 11%;
  }

  @media (max-width: 650px) {
    width: 20%;
  }

  img {
    max-height: 100%;
    max-width: 70%;
    margin-bottom: 0;

    @media (max-width: 650px) {
      max-width: 100%;
    }
  }
`

export default HomeClient
