import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import MaskedImage from '../masked-image'
import MaskedVideo from '../masked-video'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'
import Observer from '../observer'

export const fragment = graphql`
  fragment HomeIntroductionFragment on WordPress_Page_Homefields {
    introduction {
      image {
        sourceUrl
        mediaItemId
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      video {
        mediaItemUrl
      }
      title
      text
    }
  }
`

const HomeIntroduction = ({ introduction }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [isDesktop, setIsDesktop] = useState(null)
  const titleRef = useRef()
  const contentRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const contentAnimation = useSpring({
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <IntroductionBlock>
        <Left>
          {!introduction.video.mediaItemUrl ||
            (!isDesktop && (
              <MaskedImage fluid={introduction.image.imageFile.childImageSharp.fluid} />
            ))}
          {introduction.video.mediaItemUrl && isDesktop && (
            <MaskedVideo video={introduction.video.mediaItemUrl} />
          )}
        </Left>
        <Right>
          <Observer element={titleRef.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
            <Title style={titleAnimation} ref={titleRef}>
              {introduction.title}
            </Title>
          </Observer>

          <Observer
            element={contentRef.current}
            toggle={animatedContent}
            setToggle={setAnimatedContent}
          >
            <MainContent style={contentAnimation} ref={contentRef}>
              {introduction.text}
            </MainContent>
          </Observer>
        </Right>
      </IntroductionBlock>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  margin: 0 auto;

  @media (max-width: 1260px) {
  }

  @media (max-width: 650px) {
  }
`

const IntroductionBlock = styled.div`
  width: 100%;
  margin-bottom: 25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 8rem;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  ${aspectRatio(200, 200)}

  @media (max-width: 769px) {
    ${aspectRatio(16, 11)}
    width: 100%;
    margin-bottom: 8rem;
  }

  @media (max-width: 650px) {
    ${aspectRatio(428, 389)}
    height: 50%;
    margin-bottom: 8.5rem;
  }

  &::after {
    content: '';
    display: block;
    opacity: 0;
  }

  > * {
    position: absolute;
  }
`

const Right = styled.div`
  width: 40%;
  margin-left: 5%;

  @media (min-width: 2000px) {
    width: 35%;
    max-width: 850px;
    margin-left: 5rem;
  }

  @media (max-width: 769px) {
    width: 75%;
    margin-left: 5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-left: 0;
  }
`

const Title = styled(animated.h2)`
  color: ${props => props.theme.colours.darkGrey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  max-width: 14ch;

  @media (max-width: 1024px) {
    font-size: 5.5rem;
  }

  @media (max-width: 650px) {
    font-size: 5rem;
    margin: 0 3rem;
    margin-bottom: 4.5rem;
  }
`

const MainContent = styled(animated.p)`
  color: ${props => props.theme.colours.darkGrey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75;
  display: inline-block;
  margin-top: 1rem;
  margin-left: 2.5rem;

  @media (max-width: 1024px) {
    margin-top: 1.5rem;
    font-size: 1.85rem;
    line-height: 1.9;
  }

  @media (max-width: 650px) {
    margin: 0 3rem;
    font-size: 2.25rem;
    line-height: 1.9;
  }
`

export default HomeIntroduction
