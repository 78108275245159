import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { TransitionContext } from '../context/transition-context'

import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'

import MaskedImage from '../masked-image'
import MaskedVideo from '../masked-video'

export const fragment = graphql`
  fragment HomeWorkFragment on WordPress_Page_Homefields {
    work {
      image {
        sourceUrl
        altText
      }
      smallTitle
      title
      content
      buttonText
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
      featuredWork {
        ... on WordPress_Project {
          slug
          categories {
            nodes {
              name
            }
          }
          workFields {
            featuredInfos {
              subtitle
              title
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imagePortrait {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              videoSquare {
                mediaItemUrl
              }
              videoPortrait {
                mediaItemUrl
              }
              videoImageFallback {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageOrVideo
            }
          }
        }
      }
    }
  }
`

const HomeWork = ({ work }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  // const [workInView, setWorkInView] = useState(false)
  const [animatedSvg, setAnimatedSvg] = useState(false)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const svgAnimation = useSpring({
    opacity: animatedSvg ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <WorkBlock>
        <IntroWork>
          <Observer element={ref.current} toggle={animatedSvg} setToggle={setAnimatedSvg}>
            <WrapImg style={svgAnimation} ref={ref}>
              <img src={work.image.sourceUrl} alt={work.image.altText} />
            </WrapImg>
          </Observer>

          <WrapText>
            <Observer element={ref.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
              <Title style={titleAnimation}> {work.title} </Title>
            </Observer>
          </WrapText>
        </IntroWork>

        <WorkFeatured>
          {work.featuredWork.map((project, i) => (
            <WorkItem
              key={project.slug}
              to={`/work/${project.slug}`}
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/work/${project.slug}`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              <WrapWorkImage>
                {project.workFields.featuredInfos.imageOrVideo === 'image' && (
                  <>
                    {isDesktop === true && (
                      <MaskedImage
                        style={{ position: 'absolute' }}
                        fluid={
                          (i === 0 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 3 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 4 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 7 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 8 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 11 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 12 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 15 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 16 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 19 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 20 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 223 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 24 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 27 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 28 && project.workFields.featuredInfos.imagePortrait) ||
                          (i === 31 && project.workFields.featuredInfos.imagePortrait)
                            ? project.workFields.featuredInfos.imagePortrait.imageFile
                                .childImageSharp.fluid
                            : project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid
                        }
                        alt={project.workFields.featuredInfos.image.altText}
                        hover="true"
                      />
                    )}
                    {!isDesktop === true && (
                      <MaskedImage
                        style={{ position: 'absolute' }}
                        fluid={project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid}
                        alt={project.workFields.featuredInfos.image.altText}
                        hover="true"
                      />
                    )}
                  </>
                )}
                {project.workFields.featuredInfos.imageOrVideo === 'video' && (
                  <>
                    {isDesktop === true && (
                      <MaskedVideo
                        video={
                          (i === 0 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 3 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 4 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 7 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 8 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 11 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 12 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 15 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 16 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 19 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 20 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 223 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 24 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 27 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 28 && project.workFields.featuredInfos.videoPortrait) ||
                          (i === 31 && project.workFields.featuredInfos.videoPortrait)
                            ? project.workFields.featuredInfos.videoPortrait.mediaItemUrl
                            : project.workFields.featuredInfos.videoSquare.mediaItemUrl
                        }
                        alt={project.workFields.featuredInfos.image.altText}
                        hover="true"
                      />
                    )}
                    {!isDesktop === true && (
                      <MaskedImage
                        style={{ position: 'absolute' }}
                        fluid={
                          project.workFields.featuredInfos.videoImageFallback.imageFile
                            .childImageSharp.fluid
                        }
                        alt={project.workFields.featuredInfos.image.altText}
                        hover="true"
                      />
                    )}
                  </>
                )}
              </WrapWorkImage>
              <ItemDesc>
                {project.categories.nodes.map((cat, n) => (
                  <span key={n}>{cat.name} </span>
                ))}
                <h2>{project.workFields.featuredInfos.title}</h2>
                <p>{project.workFields.featuredInfos.subtitle}</p>
              </ItemDesc>
            </WorkItem>
          ))}
        </WorkFeatured>
      </WorkBlock>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  margin: 0 auto;
  background-color: #242424;
  transition: background-color 0.35s ease-in-out;
`

const WorkBlock = styled.div`
  width: 100%;
  height: 100%;
`

const IntroWork = styled.div`
  display: block;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 15rem;
  margin: 0 auto 10rem auto;
  max-width: ${props => props.theme.sizes.maxWidth};

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
    margin: 0 auto;
    padding-top: 12rem;
  }
`

const WrapImg = styled(animated.div)`
  width: 41%;
  margin-bottom: 2.5rem;

  @media (min-width: 1800px) {
    margin-bottom: 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  img {
    width: 80%;
    margin-right: 0;
    float: right;

    @media (max-width: 1024px) {
      width: 90%;
    }

    @media (max-width: 650px) {
      width: 80%;
    }
  }
`

const WrapText = styled.div`
  margin-bottom: -20%;
  width: 45%;

  @media (min-width: 2100px) {
    width: 40%;
    max-width: 850px;
  }

  @media (min-width: 1800px) {
    margin-bottom: -20rem;
  }

  @media (max-width: 1260px) {
    margin-bottom: -10rem;
  }

  @media (max-width: 1024px) {
    width: 48%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 0;
  }
`

const WorkFeatured = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding-bottom: 10rem;
  padding-top: 1rem;

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
    margin: 5rem auto;
    padding-bottom: 5rem;
  }
`

const WrapWorkImage = styled.div`
  position: relative;

  ${Img} {
    top: 0;
    left: 0;
  }
`

const WorkItem = styled(Link)`
  width: calc(49% - 2rem);

  @media (max-width: 769px) {
    width: 47%;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }

  ${WrapWorkImage} {
    ${aspectRatio(537, 469)}
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
    margin-bottom: 5rem;

    &:nth-child(odd) {
      width: 100%;

      ${WrapWorkImage} {
        width: 100%;
        padding: 0;
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(even) {
      width: 100%;

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
        width: 100%;
        padding: 0;
      }
    }
  }

  @media (min-width: 650px) {
    &:nth-child(4n + 1) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }

  @media (min-width: 700px) {
    &:nth-child(4n + 1) {
      width: 47%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }

    &:nth-child(4n) {
      width: 47%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
      }
    }
  }
`

const ItemDesc = styled.div`
  margin-top: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.22rem;
    text-transform: uppercase;
    margin-right: 1.5rem;
    display: inline-block;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  h2 {
    color: #fff;
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;
    transition: color 0.25s ease;
    margin-top: 1rem;

    @media (max-width: 650px) {
      margin-top: 2rem;
      font-size: 5rem;
    }
  }

  p {
    color: #fff;
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }
`

const Title = styled(animated.h2)`
  /* color: var(--textgrey); */
  color: #fff;
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 8rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    line-height: 1.25;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 7rem;
    text-align: center;
  }
`

export default HomeWork
